@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* body {
    @apply bg-[url('homebg.png')] bg-cover bg-center bg-no-repeat bg-fixed;
    flex-direction: column;
    min-height: 100vh;
  }
  */
  .markdown-content h1 { font-size: 2.5em; font-weight: bold; margin-top: 1em; margin-bottom: 0.5em; }
  .markdown-content h2 { font-size: 2em; font-weight: bold; margin-top: 1em; margin-bottom: 0.5em; }
  .markdown-content h3 { font-size: 1.5em; font-weight: bold; margin-top: 1em; margin-bottom: 0.5em; }
  .markdown-content p { margin-bottom: 1em; }
  .markdown-content ul, .markdown-content ol { margin-bottom: 1em; padding-left: 2em; }
  .markdown-content li { margin-bottom: 0.5em; }
  
  .hero-image {
    @apply bg-[url('homebg.png')] bg-cover bg-center bg-no-repeat rounded-lg overflow-hidden;
  }

  .study-anywhere-image {
    @apply bg-[url('studyanywhere.png')] bg-cover bg-center bg-no-repeat;
  }

  .learn-image {
    @apply bg-[url('learn.png')] bg-cover bg-center bg-no-repeat;
  }

  .stay-on-track-image {
    @apply bg-[url('stayontrack.png')] bg-cover bg-center bg-no-repeat;
  }

  .practice-image {
    @apply bg-[url('practice.png')] bg-cover bg-center bg-no-repeat;
  }

  .quiz-header {
    @apply flex justify-between items-center mb-5;
  }

  .progress-bar-container {
    @apply flex-grow h-2.5 bg-gray-200 rounded-full ml-5 overflow-hidden;
    @apply dark:bg-gray-700; /* Adjust the progress bar container color for dark mode */
  }

  .progress-bar {
    @apply h-full bg-green-500 transition-all duration-300 ease-in-out;
    @apply dark:bg-green-400; /* Adjust the progress bar color for dark mode */
  }

  
  .content {
    @apply text-left text-gray-800 w-full max-w-md p-6 sm:p-8 rounded-xl;
    @apply dark:text-gray-200;
  }
  
  .quiz-style-container {
    @apply bg-white shadow-md;
    @apply dark:bg-gray-800;
  }
  
  .cta-button {
    @apply inline-block px-6 py-3 bg-blue-500 text-white no-underline rounded-lg font-bold transition-colors duration-300 ease-in-out hover:bg-blue-600;
    @apply dark:bg-blue-600 dark:hover:bg-blue-700;
  }

  .secondary-button {
    @apply inline-block px-6 py-3 bg-gray-200 text-gray-800 no-underline rounded-lg font-bold transition-colors duration-300 ease-in-out hover:bg-gray-300;
    @apply dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600;
  }
  
  /* Header styles */
  .header {
    @apply bg-white shadow-md fixed top-0 left-0 right-0 z-50;
    @apply dark:bg-gray-800;
  }
  
  .nav-container {
    @apply container mx-auto px-4 py-3 flex justify-between items-center;
  }
  
  .nav-logo {
    @apply text-xl font-bold text-gray-800;
    @apply dark:text-gray-200;
  }
  
  .nav-links {
    @apply hidden md:flex space-x-4;
  }
  
  .nav-link {
    @apply text-gray-600 hover:text-gray-800 transition-colors duration-200;
    @apply dark:text-gray-300 dark:hover:text-gray-100;
  }
  
  .mobile-menu-button {
    @apply md:hidden w-10 h-10 flex flex-col justify-center items-center focus:outline-none;
  }
  
  .hamburger-line {
    @apply w-6 h-0.5 bg-gray-600 transition-all duration-300 ease-in-out;
    @apply dark:bg-gray-300;
  }
  
  .hamburger-line + .hamburger-line {
    @apply mt-1.5;
  }
  
  .mobile-menu-button.active .hamburger-line:nth-child(1) {
    @apply transform rotate-45 translate-y-2;
  }
  
  .mobile-menu-button.active .hamburger-line:nth-child(2) {
    @apply opacity-0;
  }
  
  .mobile-menu-button.active .hamburger-line:nth-child(3) {
    @apply transform -rotate-45 -translate-y-2;
  }
  
  .mobile-menu {
    @apply md:hidden absolute top-full left-0 right-0 bg-white shadow-md max-h-0 overflow-hidden transition-all duration-300 ease-in-out;
    @apply dark:bg-gray-800;
  }
  
  .mobile-menu.open {
    @apply max-h-screen;
  }
  
  .mobile-menu-link {
    @apply block px-4 py-3 text-gray-800 hover:bg-gray-100 transition-colors duration-200;
    @apply dark:text-gray-200 dark:hover:bg-gray-700;
  }
  
  /* Add this new style for the mobile Continue Quiz button */
  .mobile-menu-link.continue-quiz {
    @apply text-green-600 font-semibold hover:bg-green-50;
    @apply dark:text-green-400 dark:hover:bg-green-900;
  }
  
  /* Add this new style for the mobile Clear Data button */
  .mobile-menu-link.clear-data {
    @apply text-red-600 font-semibold hover:bg-red-50;
    @apply dark:text-red-400 dark:hover:bg-red-900;
  }

  .continue-quiz-button {
    @apply bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out;
    @apply dark:bg-green-600 dark:hover:bg-green-700 dark:text-white;
  }

  .clear-data-button {
    @apply bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out ml-2;
    @apply dark:bg-red-600 dark:hover:bg-red-700 dark:text-white;
  }
  /* Quiz container styles */
  .quiz-container {
    @apply flex flex-col pt-24 md:pt-28 px-4 md:px-6 max-w-2xl mx-auto;
    min-height: calc(100vh - 128px); /* Adjust 128px based on your header and footer heights */
    padding-bottom: 64px; /* Adjust this value based on your footer height */
  }
  
  .quiz-content {
    @apply bg-white rounded-lg shadow-md p-6 md:p-8;
    @apply dark:bg-gray-800 dark:text-gray-200;
  }

  .quiz-completion-container {
    @apply bg-white rounded-lg shadow-md p-6 md:p-8 text-center;
    @apply dark:bg-gray-800 dark:text-gray-200;
  }
  
  .timer {
    @apply text-lg font-bold mb-4;
    @apply dark:text-gray-200;
  }
  
  .question_text {
    @apply text-xl font-semibold mb-4;
    @apply dark:text-gray-200;
  }
  
  .hint-button {
    @apply bg-blue-500 text-white px-4 py-2 rounded mb-4 hover:bg-blue-600 transition-colors duration-200;
  }
  
  .hint-text {
    @apply bg-gray-100 p-4 rounded mb-4 italic;
    @apply dark:bg-gray-700 dark:text-gray-300;
  }
  
  .option-container {
    @apply mb-4;
  }
  
  .option-button {
    @apply w-full text-left px-4 py-2 border border-gray-300 rounded transition-colors duration-200;
    @apply dark:border-gray-600 dark:text-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600;
  }
  
  .option-button:hover {
    @apply bg-gray-100;
    @apply dark:bg-gray-600;
  }
  
  .option-button.correct {
    @apply bg-green-100 border-green-500;
    @apply dark:bg-green-800 dark:border-green-400 dark:text-green-200;
  }
  
  .option-button.incorrect {
    @apply bg-red-100 border-red-500;
    @apply dark:bg-red-800 dark:border-red-400 dark:text-red-200;
  }

  /* Input field styles */
  input[type="email"], input[type="text"], input[type="password"] {
    @apply bg-white border border-gray-300 rounded px-3 py-2;
    @apply dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200;
  }
  
  .correct-answer {
    @apply mt-2 text-green-600 font-semibold;
  }
  
  .explanation-section {
    @apply mt-4 bg-gray-50 p-4 rounded-lg;
    @apply dark:bg-gray-700;
  }
  
  .explanation-section p {
    @apply mb-2 font-semibold;
  }
  
  .explanation-textarea {
    @apply w-full p-2 border border-gray-300 rounded mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500;
    @apply dark:bg-gray-600 dark:border-gray-500 dark:text-gray-200;
  }
  
  .button-container {
    @apply mt-4 flex justify-end;
  }
  
  .save-button, .next-button {
    @apply bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 transition-colors duration-200;
  }
  
  .confirmation-modal {
    @apply fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50;
  }
  
  .confirmation-modal-content {
    @apply bg-white p-6 rounded-lg shadow-xl max-w-sm w-full;
    @apply dark:bg-gray-800 dark:text-gray-200;
  }
  
  .confirmation-modal p {
    @apply mb-4 text-lg;
  }
  
  .confirmation-modal-buttons {
    @apply flex justify-end space-x-4;
  }
  
  .confirmation-modal-buttons button {
    @apply px-4 py-2 rounded text-white font-semibold;
  }
  
  .confirmation-modal-buttons button:first-child {
    @apply bg-red-500 hover:bg-red-600;
  }
  
  .confirmation-modal-buttons button:last-child {
    @apply bg-gray-500 hover:bg-gray-600;
  }
  
  /* Quiz finished styles */
  .quiz-finished-buttons {
    @apply mt-8 space-y-4;
  }
  
  .email-button, .profile-button {
    @apply w-full bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600 transition-colors duration-200;
  }
  
  .typeform-container {
    @apply flex-grow flex items-center justify-center p-4;
    height: calc(100vh - 64px - 64px); /* Adjust these values based on your header and footer heights */
  }

  .typeform-container {
    position: fixed;
    top: 64px; /* Adjust this value to match your header height */
    left: 0;
    width: 100%;
    height: calc(100vh - 64px); /* Subtract the header height */
    z-index: 1000;
  }
}

@layer components {
  .main-container {
    @apply flex flex-col min-h-screen;
  }

  .main-content {
    @apply flex-grow flex flex-col;
  }

  .min-page-height {
    @apply min-h-[calc(100vh-128px)]; /* Adjust 128px based on your header and footer heights */
  }
}

@layer components {
  .new-home-container {
    @apply bg-gray-100 dark:bg-gray-900 min-h-screen py-6 px-4 sm:px-6 lg:px-8;
  }

  .new-home-content {
    @apply max-w-7xl mx-auto pt-16; /* Adjusted to account for the fixed header */
  }

  .new-home-inner {
    @apply bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden mt-16 p-6 sm:p-8;
  }

  /* Add these new styles for the Welcome Back and Quiz Info sections */
  .welcome-back-container, .quiz-info-container {
    @apply max-w-md mx-auto;
  }

  .welcome-back-container h1, .quiz-info-container h1 {
    @apply text-2xl sm:text-3xl font-bold mb-4 text-center text-gray-800 dark:text-gray-200;
  }

  .welcome-back-container p, .quiz-info-container p {
    @apply text-base sm:text-lg mb-6 opacity-80 text-center text-gray-600 dark:text-gray-300;
  }

  .welcome-back-container .space-y-4, .quiz-info-container .space-y-4 {
    @apply mt-8;
  }

  .quiz-info-container ul {
    @apply list-disc pl-5 py-3 space-y-2 text-gray-700 dark:text-gray-300;
  }

  /* Adjust the existing button styles */
  .cta-button, .secondary-button {
    @apply w-full text-center py-3 px-6 rounded-lg font-bold transition-colors duration-300 ease-in-out;
  }

  .cta-button {
    @apply bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700;
  }

  .secondary-button {
    @apply bg-gray-200 text-gray-800 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600;
  }
}

@layer utilities {
  .dark-mode-toggle {
    @apply bg-gray-200 dark:bg-gray-600 w-14 h-7 rounded-full relative cursor-pointer transition-colors duration-300 ease-in-out flex items-center justify-between px-1;
  }

  .dark-mode-toggle::after {
    content: '';
    @apply absolute w-5 h-5 bg-white rounded-full left-1 top-1 transition-transform duration-300 ease-in-out;
  }

  .dark-mode-toggle.active::after {
    @apply transform translate-x-7;
  }

  .dark-mode-toggle .sun-icon,
  .dark-mode-toggle .moon-icon {
    @apply w-4 h-4 text-yellow-500 dark:text-gray-300 z-10;
  }
}

.loading-container {
  @apply flex flex-col items-center justify-center fixed inset-0 bg-white dark:bg-gray-900 z-50;
}

.loading-spinner {
  @apply w-12 h-12 border-4 border-blue-200 dark:border-blue-900 rounded-full;
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}

.loading-text {
  @apply mt-4 text-lg font-semibold text-gray-700 dark:text-gray-300;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.sign-in-out-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #4A5568;
  color: white;
  border-radius: 0.25rem;
  transition: background-color 0.2s;
}

.sign-in-out-button:hover {
  background-color: #2D3748;
}

.dark .sign-in-out-button {
  background-color: #718096;
}

.dark .sign-in-out-button:hover {
  background-color: #4A5568;
}