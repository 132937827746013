.card-shadow {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .feature-card {
    transition: transform 0.2s ease-in-out;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
  }

@media (max-width: 768px) {
  .new-home-container {
    padding: 1rem;
  }

  .new-home-content {
    margin-top: 1rem;
  }
}